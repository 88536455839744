module.exports = {
  siteTitle: 'NanoKarrin',
  manifestName: 'NanoKarrin',
  manifestShortName: 'NanoKarrin', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`,
  authorName: 'NanoKarrin',
  download: 'Pobierz',
  // social
  socialLinks: [
    {
      icon: 'fab fa-youtube',
      name: 'Youtube',
      url: 'https://www.youtube.com/user/fandubbing',
    },
    {
      icon: 'fab fa-discord',
      name: 'Discord',
      url: 'https://discordapp.com/invite/SzuPRQ?utm_source=Discord%20Widget&utm_medium=Connect',
    },
    {
      icon: 'fab fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/nanokarrin',
    },
    {
      icon: 'fab fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/NanoKarrin',
    },
    {
      icon: 'fab fa-instagram',
      name: 'Facebook',
      url: 'https://instagram.com/nanokarrin',
    },
  ],
};
