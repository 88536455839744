import React from 'react';

export default function PageFooter() {
  return (
    <footer id="footer">
      <ul className="copyright">
          <li><a href={'https://nanokarrin.pl'}>NanoKarrin</a></li>
          <li><a href={'https://html5up.net/'}>HTML5UP</a></li>
      </ul>
    </footer>
  );
}
